<template>
  <div class="admin-view-container compensation-flow-view">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item label="选择驾校">
          <select-dict-remote
            filterable
            show-all
            v-model="tableQuery.drvSchoolCode"
            :api="drvSchoolGetSchool"
            :query="{getType: 2}"
            value-field="drvSchoolCode"
            label-field="drvSchoolForShort"
            style="width: 220px"></select-dict-remote>
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker
            @change="dateValueChangeHandler"
            v-model="dateValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: auto; width: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column prop="drvSchoolForShort" label="驾校名称"></el-table-column>
        <el-table-column prop="enrollNum" label="报名人数"></el-table-column>
        <el-table-column prop="joinNUm" label="加入人数"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link type="primary" @click="detailHandler(scope.row)">查看</el-link>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
import {drvStudentEnrollGetStatistics, drvSchoolGetSchool} from '@/api/index';
import tableMinx from '@/mixin/table-page';
import moment from 'moment';
export default {
  mixins: [tableMinx(drvStudentEnrollGetStatistics)],
  data() {
    return {
      drvSchoolGetSchool,
      loading: false,
      dateValue: [],
      tableQuery: {
        drvSchoolCode: '',
        startTime: '',
        endTime: '',
      },
    };
  },
  created() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let date = new Date().getDate();
    let todayStart = new Date(`${year} ${month} ${date} 00:00:00`);
    let todayEnd = moment(todayStart).add(1, 'days').subtract(1, 'seconds')._d;
    this.dateValue = [todayStart, todayEnd];
    this.dateValueChangeHandler(this.dateValue);
  },
  methods: {
    dateValueChangeHandler(d) {
      if (d) {
        this.tableQuery.startTime = Date.parse(d[0]) / 1000;
        this.tableQuery.endTime = Date.parse(d[1]) / 1000;
      } else {
        this.tableQuery.startTime = '';
        this.tableQuery.endTime = '';
      }
    },

    async detailHandler(row) {
      let vNode = this.$createElement((await import('./detail.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
          startTime: this.tableQuery.startTime,
          endTime: this.tableQuery.endTime,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: row.drvSchoolForShort,
        width: '600px',
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>
  
  <style lang="scss">
.compensation-flow-view {
  .input-with-select {
    width: 280px;
  }
}
</style>
  